<template>
  <div>
    <v-menu
      offset-y
      bottom
      left
      origin="top right"
      max-height="400"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-badge bordered :content="unreadCount" color="red" overlap>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-card>
        <v-list dense>
          <!-- Display the mark all as read button if there are unread notifications -->
          <v-list-item
            v-if="unreadCount > 0"
            @click="markAllAsRead"
            class="text-center"
          >
            <v-list-item-content>
              <v-list-item-title class="text-center">
                Mark all as read
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Display individual notifications -->
          <v-list-item
            v-for="notification in notifications.filter(
              (elm) => elm.is_read == 0
            )"
            :key="notification.id"
            :class="{ 'notification-read': notification.is_read }"
            @click="openNotification(notification)"
          >
            <!-- Icon dynamically chosen based on notification type -->
            <v-list-item-avatar>
              <v-icon :color="getNotificationColor(notification.notif_type)">
                {{ getNotificationIcon(notification.notif_type) }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ notification.title }}</v-list-item-title>
              <v-list-item-subtitle>{{
                notification.message
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <!-- Display the mark as read button only if the notification is unread -->
            <v-list-item-action v-if="!notification.is_read">
              <v-btn icon @click.stop="markAsRead(notification.id)">
                <v-icon color="green">mdi-check</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-btn @click="viewAllNotifications">View All</v-btn>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import GET_NOTIFICATIONS from "@/graphql/Notifications/GET_NOTIFICATIONS.gql";
import MARK_AS_READ from "@/graphql/Notifications/MARK_AS_READ.gql";
import MARK_ALL_AS_READ from "@/graphql/Notifications/MARK_ALL_AS_READ.gql";

export default {
  name: "DefaultNotifications",
  data() {
    return {
      notifications: [],
      unreadCount: 0,
    };
  },
  watch: {
    "$store.state.changed": {
      handler() {
        if (this.$store.state.changed) {
          this.refresh();
        }
      },
    },
  },
  mounted() {
    this.refresh(); // Load notifications initially
    setInterval(() => {
      this.refresh(); // Refresh notifications every minute
    }, 60000);
  },
  methods: {
    // Get icon based on notification type
    getNotificationIcon(type) {
      switch (type) {
        case "info":
          return "mdi-information-outline";
        case "warning":
          return "mdi-alert-outline";
        case "critical":
          return "mdi-alert-circle-outline";
        case "alert":
          return "mdi-alert-circle-outline";
        default:
          return "mdi-bell-outline";
      }
    },
    // Get color based on notification type
    getNotificationColor(type) {
      switch (type) {
        case "info":
          return "blue";
        case "warning":
          return "orange";
        case "critical":
          return "red";
        case "alert":
          return "red";
        default:
          return "grey";
      }
    },
    viewAllNotifications() {
      this.$router.push({ name: "notifHistory" });
    },
    // Open notification and handle any required logic
    openNotification() {
      // if (!notification.is_read) {
      //   this.markAsRead(notification.id); // Mark as read when opened
      // }
      // Handle other actions, e.g., opening a notification detail view
    },

    // Mark individual notification as read
    async markAsRead(notificationId) {
      await this.$apollo.mutate({
        mutation: MARK_AS_READ,
        variables: { id: notificationId },
      });
      // Update UI
      this.notifications = this.notifications.map((n) =>
        n.id === notificationId ? { ...n, is_read: true } : n
      );
      this.unreadCount = this.notifications.filter((n) => !n.is_read).length;
    },

    // Mark all notifications as read
    async markAllAsRead() {
      if (this.unreadCount > 0) {
        await this.$apollo.mutate({
          mutation: MARK_ALL_AS_READ,
          variables: { user_id: this.$store.state.me.id },
        });
        // Update UI after marking all as read
        this.notifications = this.notifications.map((n) => ({
          ...n,
          is_read: true,
        }));
        this.unreadCount = 0;
      }
    },

    // Refresh notifications list from the server
    async refresh() {
      const result = await this.$apollo.query({
        query: GET_NOTIFICATIONS,
        variables: { user_id: this.$store.state.me.id },
        fetchPolicy: "network-only", // Ensures fresh data
      });
      if (result) {
        this.notifications = result.data.userNotifications;
        this.unreadCount = this.notifications.filter((n) => !n.is_read).length;
      }
    },
  },
};
</script>

<style scoped>
.notification-read {
  opacity: 0.6;
}
.mark-all {
  background-color: #f0f0f0;
  cursor: pointer;
}
</style>
